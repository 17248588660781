import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/DropdownContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/HamburgerMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/HeaderLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Shared/ProgressiveScroll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ThemeToggle.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/Header.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/NavLink.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/Footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/components/QuestionnaireCTA.module.css");
